<template>
  <div class="goods-card">
    <div class="shop-title">
      <img :src="goodsInfo.sell_info.head_img || defaultImg" class="img" />
      <span class="title">{{ goodsInfo.sell_info.nickname }}</span>
    </div>
    <div class="goods-synopsis">
      <img :src="goodsInfo.used_img" class="goods-img" />
      <div class="goods-details">
        <div class="goods-content">
          {{ goodsInfo.used_name }}
        </div>
        <span class="price">
          ￥{{ toInteger(goodsInfo.price) || "00"
          }}{{ twoFloating(goodsInfo.price) || ".00" }}
        </span>
      </div>
    </div>
    <div class="postage">
      <span class="postage-lable">交易方式</span>
      <span class="postage-text">{{ isDeliver(goodsInfo.deliver_type) }}</span>
    </div>
  </div>
</template>

<script>
import tools from "@/common/js/tools";

export default {
  name: "goods-card",
  props: {
    goodsInfo: {
      type: Object,
    },
  },
  data() {
    return {
      defaultImg: require("@/assets/used/shop_logo.png"),
    };
  },
  methods: {
    isDeliver(num) {
      // 运费
      let str = "";
      if (num * 1 === 1) {
        str = "包邮";
      } else if (num * 1 === 2) {
        str = "到付";
      } else {
        str = "自提";
      }
      return str;
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // if (typeof num === "number") {
      //   price = String(num).split(".")[1];
      //   if (price !== undefined && price.length === 1) {
      //     price = `.${price}0`;
      //   } else {
      //     price === undefined ? (price = ".00") : (price = `.${price}`);
      //   }
      // }
      // return price;
    },
  },
};
</script>

<style lang="less" scoped>
.goods-card {
  background-color: #fff;
  border-radius: 13px;
  padding: 12px;
  margin-top: 12px;
}
.shop-title {
  display: flex;
  align-items: center;
  font-size: 17px;
  .img {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
}
.goods-synopsis {
  display: flex;
  margin-top: 22px;
  .goods-img {
    flex-shrink: 0;
    display: block;
    width: 110px;
    height: 110px;
    margin-right: 12px;
    border-radius: 10px;
  }
  .goods-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .goods-content {
    flex-shrink: 0;
    width: 150px;
    height: 64px;
    font-size: 16px;
    display: -webkit-box; /**对象作为伸缩盒子模型展示**/
    -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp: 3; /**显示的行数**/
    overflow: hidden; /**隐藏超出的内容**/
  }
  .price {
    flex-shrink: 0;
    font-size: 17px;
    font-weight: 500;
    padding-left: 32px;
  }
}
.postage {
  font-size: 17px;
  margin-top: 26px;
  .postage-lable {
    color: #6f6f6f;
    margin-right: 16px;
  }
}
</style>
