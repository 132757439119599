<template>
  <div>
    <nav class="nav-bar">
      <van-icon
        name="arrow-left"
        class="last-step"
        @click="lastStep"
        color="#9B9B9B"
      />
      <span>确认订单</span>
    </nav>
    <div class="address" v-if="goodsInfo.deliver_type * 1 !== 3">
      <van-icon
        name="location-o"
        class="icon-location"
        size="24"
        color="#9B9B9B"
      />
      <div
        class="address-content"
        @click="selectAddress"
        v-if="goodsInfo.deliver_type !== 3"
      >
        <span class="select-address">{{
          addressInfo.consignee || "请选择收货地址"
        }}</span>
        <span class="phone">
          {{ addressInfo.phone || "" }}
        </span>
        <span
          v-if="addressInfo.phone === '' && addressInfo.consignee === ''"
          class="select-address"
        >
          {{ "请选择收货地址" }}
        </span>
        <br />
        <span class="address-details">
          {{ addressInfo.city_names || "请选择你的收货地址" }}
          {{ addressInfo.address || "请选择你的收货地址" }}
        </span>
      </div>
      <van-icon name="arrow" class="go-to-address" size="24" color="#9B9B9B" />
    </div>
    <GoodsCard :goodsInfo="goodsInfo"></GoodsCard>
    <SumCard :goodsInfo="goodsInfo"></SumCard>
    <van-submit-bar
      :label="`共${'1'}件，`"
      :price="price || 0"
      button-text="提交订单"
      class="submit-order"
      @submit="debounceFn"
    />
    <SelectAddress
      :is-show="showPopup"
      @getAddress="getAddress"
    ></SelectAddress>
    <PayOrder
      :showPay="showPay"
      :orderId="orderId"
      @closeOverlay="closeOverlay"
    ></PayOrder>
  </div>
</template>

<script>
import SumCard from "./moudles/sum-card/sum-card";
import GoodsCard from "./moudles/goods-card/goods-card";
import SelectAddress from "@/components/select-address/select-address";
import PayOrder from "../pay-order";
import { getAddressInfo } from "@/api/member";
import { getGoodsDetails, setCreateOrder } from "@/api/used";
import { debounce } from "lodash";
import { Toast } from "vant";
export default {
  name: "creation-order",
  components: {
    GoodsCard,
    SumCard,
    SelectAddress,
    PayOrder,
  },
  data() {
    return {
      goodsInfo: {
        sell_info: {
          head_img: "",
          id: "",
          nickname: "",
        },
        deliver_type: 1,
      },
      goodsId: this.$route.query.goodsId,
      showPopup: false,
      showPay: false,
      orderId: 0,
      addressInfo: "",
      order: {
        goods_id: "",
        address_id: "",
      },
      debounceFn: null,
    };
  },
  created() {
    this.debounceFn = debounce(this.submitOrder, 500);
  },

  mounted() {
    this.getGoodsDetails();
    this.getAddressInfo();
  },
  computed: {
    price() {
      let money = String(this.goodsInfo.price);
      let moneyArr = [];
      if (money.indexOf(".") !== -1) {
        moneyArr = money.split(".");
        if (moneyArr[1].length < 2) {
          money = `${moneyArr[0]}${moneyArr[1]}0`;
        } else {
          money = `${moneyArr[0]}${moneyArr[1]}`;
        }
      } else {
        money = `${money}00`;
      }
      money = money * 1;
      return money;
    },
  },
  methods: {
    async getGoodsDetails() {
      // 获取商品详情
      const res = await getGoodsDetails({ id: this.goodsId, type: 2 });
      this.goodsInfo = res.data;
    },
    selectAddress() {
      this.showPopup = true;
    },
    getAddress(locationId) {
      this.getAddressInfo(locationId);
      this.showPopup = false;
    },
    submitOrder() {
      //提交订单
      this.order.goods_id = this.goodsInfo.id;
      if (this.goodsInfo.deliver_type !== 3) {
        if (this.order.address_id <= 0) {
          Toast.fail("请选择地址");
          return false;
        }
      }
      if (this.order.goods_id > 0) {
        this.setCreateOrder();
      } else {
        Toast.fail("订单正在初始化");
      }
    },
    closeOverlay() {
      this.showPay = false;
      this.$router.replace({
        name: "used-details",
        query: {
          goodsId: this.goodsInfo.id,
        },
      });
    },
    async getAddressInfo(locationId) {
      // 获取地址信息
      const result = await getAddressInfo({ id: locationId });
      if (result.code * 1 === 1) {
        this.addressInfo = result.data;
        this.order.address_id = result.data.id;
      } else {
        Toast.fail("地址选择错误");
      }
    },
    async setCreateOrder() {
      //创建订单
      const result = await setCreateOrder(this.order);
      console.log(result);
      if (result.code === 1) {
        this.orderId = result.data.order_id;
        this.showPay = true;
      } else {
        Toast.fail("当前商品已售出");
        this.$router.replace({
          name: "used-details",
          query: {
            goodsId: this.goodsInfo.id,
          },
        });
      }
    },
    lastStep() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
@rowHeight: 58px; //行高
.nav-bar {
  position: relative;
  height: @rowHeight;
  line-height: @rowHeight;
  text-align: center;
  font-size: 21px;
  background-color: #fff;
  border-bottom: 1px solid rgb(243, 243, 243);
  .last-step {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
.address {
  position: relative;
  padding: 16px 66px 12px 44px;
  background-color: #fff;
  border-radius: 0 0 13px 13px;
  .icon-location {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
  .go-to-address {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }
  .select-address {
    font-size: 17px;
    font-weight: 500;
  }
  .phone {
    font-size: 18px;
    color: #aaaaaa;
  }
  .address-details {
    margin-top: 6px;
    font-size: 16px;
    color: #6f6f6f;
  }
}
.submit-order {
  border-top: 1px solid rgb(243, 243, 243);
}
</style>
