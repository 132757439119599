<template>
  <ul class="sum-card">
    <li class="price-list">
      <span class="lable">商品总价</span>
      <span class="goods-price font">
        ￥{{ toInteger(goodsInfo.price) || "00"
        }}{{ twoFloating(goodsInfo.price) || ".00" }}
      </span>
    </li>
    <li class="price-list">
      <span class="lable">合计</span>
      <span class="total-price font">
        ￥{{ toInteger(goodsInfo.price) || "00"
        }}{{ twoFloating(goodsInfo.price) || ".00" }}
      </span>
    </li>
  </ul>
</template>

<script>
import tools from "@/common/js/tools";

export default {
  name: "sum-card",
  props: {
    goodsInfo: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // if (typeof num === "number") {
      //   price = String(num).split(".")[1];
      //   if (price !== undefined && price.length === 1) {
      //     price = `.${price}0`;
      //   } else {
      //     price === undefined ? (price = ".00") : (price = `.${price}`);
      //   }
      // }
      // return price;
    },
  },
};
</script>

<style lang="less" scoped>
.sum-card {
  background-color: #fff;
  border-radius: 13px;
  padding: 12px 12px;
  margin-top: 12px;
}
.price-list {
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  .font {
    font-weight: 500;
  }
  .total-price {
    color: #ed301d;
  }
}
</style>
